<template>
    <section class="competition-nth">
        <div class="title">
            <h3>第{{nth}}届</h3>
        </div>
        <div class="title-real">
            <div class="word"><span>第</span></div>
            <div class="word"><span>{{nth}}</span></div>
            <div class="word"><span>届</span></div>
        </div>
        <p class="text1">{{text}}</p>
        <ul class="images clearfix">
            <li class="image" v-for="(item, index) in imgFileName" :key="index">
                <img :src="require(`@/assets/img/competition/${item}`)" alt="财商少年全国大赛">
            </li>
        </ul>
    </section>
</template>

<script>
    export default {
        name: "CompetitionNth",
        props: {
            // 图片文件名列表
            imgFileName: Array,
            // 正文
            text: String,
            // 第几届
            nth: String
        }
    }
</script>

<style lang="scss" scoped>
    .competition-nth {
        padding-bottom: 100px;
    }
    // 隐藏
    .title {
        position: absolute;
        left: -300%;
    }

    // 第几届
    .title-real {
        width: 225px;
        margin: 0 auto 35px;
    }
    .word {
        font-size: 40px;
        color: #005dad;
        display: inline-block;
        height: 66px;
        width: 66px;
        border-radius: 33px;
        border: 2px solid #ffffff;
        text-align: center;
        margin-right: 5px;
        span {
            display: block;
            margin-top: 4px;
        }
        &:nth-child(1) {
            background: #00a2e8;
        }
        &:nth-child(2) {
            background: #ffdd06;
        }

        &:nth-child(3) {
            background: #8abd19;
        }
    }

    // 正文
    .text1 {
        display: block;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        width: 73%;
        margin: 0 auto 60px;
    }

    // 5个图片
    .images {
        width: 1640px;
        margin: 0 auto;
    }
    .image {
        float: left;
        height: 300px;
        width: 300px;
        border: 14px solid;
        border-radius: 50%;
        overflow: hidden;
        img {
            margin-top: -10px;
        }
        &:nth-child(1) {
            border-color: #e00068;
        }
        &:nth-child(2) {
            border-color: #ffdc05;
            margin-top: 145px;
        }
        &:nth-child(3) {
            border-color: #8abd19;
        }
        &:nth-child(4) {
            border-color: #00a2e8;
            margin-top: 145px;
        }
        &:nth-child(5) {
            border-color: #e00068;
        }
    }
</style>