<template>
    <main class="competition">
        <div class="title">
            <h1>财商少年全国大赛</h1>
            <p>赛事介绍及愿景</p>
            <p>财商、智商、情商在一个人的成功道路上起着极其重要的作用,一起被教育家们列入了强少年的"三商"教育,而财商更是被越来越多的人认为是实现成功人生的关键。</p>
            <p>少年强则中国强!</p>
            <p>少年儿童是祖国的未来和希望，我们的使命是通过举办"财商少年"全国大赛，打造一流财商活动品牌，让财商教育从少年儿童开始，让我们的下一代成为世界一流的高素质人才，为中华民族的复兴作出应有的贡献。</p>
        </div>
        <img src="@/assets/img/competition/bg@2x.png" alt="财商少年全国大赛" class="title-img">
        <div class="sub-title">
            <h2>往届赛事</h2>
        </div>

        <competition-nth v-for="data in nthData"
                         :key="data.title"
                         :img-file-name="data.imgFileName" :text="data.text" :nth="data.nth"/>
    </main>
</template>

<script>
    import CompetitionNth from "./childComp/CompetitionNth";
    export default {
        name: "Competition",
        components: {
            CompetitionNth
        },
        metaInfo: {
            title: '中国财商少年官网-全国大赛',
            meta: [
                {
                    name: 'keyWords',
                    content: '财商, 全国少儿现金流游戏大赛,全国现金流大赛,现金流游戏大赛,财商少年游戏,中国财商少年游戏大赛,财商游戏,理财游戏'
                },
                {
                    name: 'description',
                    content: '中国财商少年每年举办全国少儿现金流游戏大赛，寓教于乐，在游戏的过程中培养孩子的财商，许孩子一个确定而美好的未来。'
                }
            ]
        },
        data() {
            return {
                nthData: [
                    {
                        nth: '一',
                        text: '首届“财商少年”全国大赛2016年7月9日拉开帷幕，历时45天,共有16个赛区，近一千多位选手参与。首届“财商少年”全国大赛总决赛于2016年8月21日在广东广播电视台隆重举行，当晚9:26广州电视综合台也做了及时的新闻报道。',
                        imgFileName: [
                            'nationalCompetition_icon_1_1.jpg',
                            'nationalCompetition_icon_1_2.jpg',
                            'nationalCompetition_icon_1_3.jpg',
                            'nationalCompetition_icon_1_4.jpg',
                            'nationalCompetition_icon_1_5.jpg',
                        ],
                    },
                    {
                        nth: '二',
                        text: '第二届“财商少年”全国大赛总决赛在2017年7月开始，历时两个月,共有18个赛区，逾两千多选手参赛。 2017年8月19日第二届“财商少年”全国大赛总决赛 在广东广播电视台隆重举行，比赛现场通过网络进行了全方位的直播，全球近5万人通过腾讯视频观看了比赛实况，整场氛围紧张又刺激，精彩纷呈！',
                        imgFileName: [
                            'nationalCompetition_icon_2_1.jpg',
                            'nationalCompetition_icon_2_2.jpg',
                            'nationalCompetition_icon_2_3.jpg',
                            'nationalCompetition_icon_2_4.jpg',
                            'nationalCompetition_icon_2_5.jpg',
                        ],
                    },
                    {
                        nth: '三',
                        text: '在过去两届的基础上，主题为“大手牵小手，未来一起走”的第三届“财商少年”全国大赛于2018年7月1日正式开赛，全国共有五大赛区（华南、华中、华北、西南、西北）经过严格的 初赛和预决赛，来自不同赛区的一百多名选手过关斩将，最终从千人中脱颖而出，获得三届全国十佳“财商少年”称号。最终，2018年8月19日，第三届“财商少年”全国大赛总决赛 在广东广播电视台圆满落幕。',
                        imgFileName: [
                            'nationalCompetition_icon_3_1.jpg',
                            'nationalCompetition_icon_3_2.jpg',
                            'nationalCompetition_icon_3_3.jpg',
                            'nationalCompetition_icon_3_4.jpg',
                            'nationalCompetition_icon_3_5.jpg',
                        ],
                    },
                    {
                        nth: '四',
                        text: '主题为“许孩子一个确定而美好的未来”的“财商少年”第四届全国财商技能大赛于2019年7月正式开锣，本届大赛历时两月，遍布全国20多个地区， 参赛人数超万人。经过层层选拔，8月17日，来自全国的两百个家庭，共四百多人，赴约“百人巅峰对决”，带着必胜的决心征战广州亚运城！ 本届赛事参与人数、规模前所未有，赛制进一步完善，影响力也有了很大的提升。最终，第四届财商技能大赛全国总决赛 在掌声与庆贺中圆满落幕！',
                        imgFileName: [
                            'nationalCompetition_icon_4_1.jpg',
                            'nationalCompetition_icon_4_2.jpg',
                            'nationalCompetition_icon_4_3.jpg',
                            'nationalCompetition_icon_4_4.jpg',
                            'nationalCompetition_icon_4_5.jpg',
                        ],
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .competition {
        background: #005dad;
    }

    .title {
        position: absolute;
        top: 0;
        text-indent: -9990px;
    }

    .title-img {
        display: block;
        width: 70%;
        margin: -90px auto 0;
    }

    .sub-title {
        width: 20%;
        height: 80px;
        margin: 100px 0 50px 0;
        font-family: YouYuan;
        font-size: 32px;
        line-height: 80px;
        text-align: right;
        color: #ffffff;
        h2 {
            margin-right: 40px;
            font-weight: normal;
        }
        background: #00a1e8;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
    }
</style>
